<template>
  <b-card bg-variant="Default">
    
    <!-- table -->
    <vue-good-table
    mode="remote"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-per-page-change="onPerPageChange"
    :totalRows="totalRecords"
    :rows="rows"
    :columns="columns"
    :isLoading.sync="isLoading"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    :pagination-options="{
        enabled: true,
        mode: 'pages',
        nextLabel: 'successiva',
        prevLabel: 'precedente',
        rowsPerPageLabel: 'righe per pagina',
        ofLabel: 'di',
        pageLabel: 'pagina', // for 'pages' mode
        perPage: 10
    }"
    styleClass="vgt-table">

      <template slot="loadingContent_stop">
        <div class="bg-primary"> animated loading ....</div>
      </template>

      <div slot="emptystate"> <div class="text-center"> Nessun dato disponibile </div> </div>

      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Codice -->
        <div
          v-if="props.column.field === 'codice'"
          class="text-nowrap"
        >
          <span class="text-nowrap">{{ props.row.codice }}</span>
        </div>

        <!-- Column: File -->
        <span v-else-if="props.column.field === 'file'">
          <div class="text-nowrap">
            <a 
              target="_blank"
              :href="props.row.file"
              class="text-reset"
              v-if="props.row.file != ''"
              >
              <feather-icon
                  :id="`tabellaarticoli-riga-${props.row.id}-download`"
                  icon="DownloadIcon"
                  class="cursor-pointer mr-1"
                  size="21"
              />
              <b-tooltip
                  title="Download"
                  class="cursor-pointer"
                  :target="`tabellaarticoli-riga-${props.row.id}-download`"
              />
            </a>
          </div>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <div class="text-nowrap">
            <feather-icon
                :id="`tabellaarticoli-riga-${props.row.id}-aggiungi-carrello`"
                icon="ShoppingCartIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="aggiungiAlCarrello(props.row.id,userData.id_azienda,userData.id_azienda_padre,props.row.descrizione)"
            />
            <b-tooltip
                title="Aggiungi al prossimo ordine"
                class="cursor-pointer"
                :target="`tabellaarticoli-riga-${props.row.id}-aggiungi-carrello`"
            />

            <feather-icon
                :id="`tabellaarticoli-riga-${props.row.id}-preview`"
                icon="InfoIcon"
                class="cursor-pointer mr-1"
                size="21"
                @click="apriPreview(props.row.id)"
            />
            <b-tooltip
                title="preview"
                class="cursor-pointer"
                :target="`tabellaarticoli-riga-${props.row.id}-preview`"
            />
          </div>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      
    </vue-good-table>

    <b-modal ref="my-modal" hide-footer centered title="Informazioni Articolo" modal-class="modal-primary" size="lg">
      <div class="d-block text-center">
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Codice</b-col>
            <b-col md="8" lg="9">{{ infomodal.codice }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Articolo</b-col>
            <b-col md="8" lg="9">{{ infomodal.descrizione }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Unità </b-col>
            <b-col md="8" lg="9">{{ infomodal.um }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Iva</b-col>
            <b-col md="8" lg="9">{{ infomodal.iva }}</b-col>
        </b-row>
        <b-row class="pb-2 text-left">
            <b-col md="4" lg="3">Ultimo aggiornamento</b-col>
            <b-col md="8" lg="9">{{ infomodal.dataupd }}</b-col>
        </b-row>
      </div>
    </b-modal>

    <b-modal
      id="modal-prevent-closing"
      ref="my-modal-pezzi"
      :title=titolo_model_aggiungi
      ok-title="Aggiungi"
      cancel-title="Annulla"
      cancel-variant="outline-secondary"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
    
      <form
        ref="form"
        @submit.stop.prevent="handleSubmit"
      >
        <b-form-group
          :state="nameState"
          label="Quanti pezzi vuoi inserire nel carrello?"
          label-for="name-input"
          invalid-feedback="Prego inserire la quantità numerica desiderata"
        >
          <b-form-input
            id="name-input"
            type="number"
            v-model="name"
            :state="nameState"
            required
            max=999999
          />
        </b-form-group>
      </form>
    </b-modal>

  </b-card>

</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { BCard, BAvatar, BBadge, BFormGroup, BFormInput, BFormSelect, BButton, BDropdown, BDropdownItem, BTooltip, BModal, VBModal } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

export default {
  components: {
    BRow,
    BCol,

    BCard,
    VueGoodTable,
    BButton,
    BTooltip,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
  },
  directives: {
    'b-modal': VBModal
  },
  data() {
    return {
      isLoading: true,
      emptystate: true,
      pageLength: 10,
      columns: [
        {
          label: 'Codice',
          field: 'codice',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca per Codice',
          },
        },
        {
          label: 'Articolo',
          field: 'descrizione',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca per Nome Articolo',
          },
        },
        {
          label: 'Prezzo',
          field: 'prezzo',
          filterOptions: {
            enabled: true,
            placeholder: 'Ricerca per Prezzo',
          },
        },
        {
          label: 'File',
          field: 'file',
        },
        {
          label: 'Operazioni',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      serverParams: {
        columnFilters: {
        },
        sort: [
            {
            field: '',
            type: ''
            }
        ],
        page: 1, 
        perPage: 10,
        licenza_pubblica: 'testlicenza',
        id_azienda: 0
      },
      infomodal: {
        emailValue: '',
        nomeecognome: '',
        startdata: '',
        salario: '',
        selected: '',
      },
      titolo_model_aggiungi: '',
      name: 0,
      nameState: null,
      id_articolo_passato: 0,
      id_azienda_passata: 0,
      id_azienda_padre_passata: 0,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        '0.00'       : 'light-danger'
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    console.log('created ....................')

    //this.rows = [];
    this.loadItems();
    
  },
  methods: {
    updateParams(newProps) {
      //console.log("updateParams --- vvv ")
      console.log(newProps)
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({perPage: params.currentPerPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [{
          type: params.sortType,
          field: this.columns[params.columnIndex].field,
        }],
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    // load items is what brings back the rows from server
    loadItems() {
        
        console.log("loadItems ......");
        //console.dir(this.serverParams)
        //this.isLoading = true;

        if(this.userData.id_azienda_padre != 0){
          //visualizzo listino dell'azienda padre
          this.updateParams({ id_azienda: parseInt(this.userData.id_azienda_padre) })
        } else {
          //come al solito -> visualizzo listo dell'azienda
          this.updateParams({ id_azienda: parseInt(this.userData.id_azienda) })
        }
        
        //this.$http.get('demo/lista_tabella_json_v3.php', {
        this.$http.get('v2/articoli/lista_articoli_selezionati', {
            params: this.serverParams 
            }).then(response => { 
                /*
                console.log("risposta vvv"); 
                console.log(response.data)
                console.log('--- ---')
                console.dir(response.data)
                console.log("response.totalRecords -> "+response.data.totalRecords);
                */

                //console.log(response.data.rows)

                
                this.totalRecords = response.data[0].contarighe;
                this.rows = response.data[1].righe;
                
                //rimuovi il loading dopo il caricamento dei dati nella tabella
                this.isLoading = false;
            
            })  
  
    },

    apriPreview(id){
      console.log("click apriPreview ...... id -> "+id);

      this.$http.get('v2/articoli/singolo_articolo/'+id)
        .then(response => { 
            console.log("risposta server --------")
            console.log(response.data)

            if(response.data.statusCode===200){
            //risposta positiva
                this.infomodal = { codice: response.data.dati.codice,
                descrizione: response.data.dati.descrizione,
                iva: response.data.dati.iva,
                um: response.data.dati.um,
                prezzo: response.data.dati.prezzo,
                giacenza: response.data.dati.giacenza,
                dataupd: response.data.dati.dataupd,
                scadenza_offerta: response.data.dati.scadenza_offerta, };

                this.$refs['my-modal'].show()
            } else {
            //risposta negativa (errore sul server)
            
            }
        }).catch(e => {
            console.log(e);
        });
    },
    aggiungiAlCarrello(id,id_azienda,id_azienda_padre,nome_articolo){
      console.log("click aggiungiAlCarrello ......\nid_articolo -> "+id+"\nid_azienda -> "+id_azienda+"\nid_azienda_padre -> "+id_azienda_padre);

      this.id_articolo_passato = id;
      this.id_azienda_passata = id_azienda;
      this.id_azienda_padre_passata = id_azienda_padre;
      
      this.titolo_model_aggiungi = nome_articolo;
      this.$refs['my-modal-pezzi'].show()

    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.nameState = valid
      return valid
    },
    resetModal() {
      this.name = 1
      this.nameState = null

    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // -> this.name
      //ok procediamo ... quantita da mettere nel carrello  in arrivo da -> this.name

      console.log("quantita passata -> "+this.name)

      this.$http.get('v2/articoli/salvanelcarrello/'+this.id_articolo_passato+'/'+this.id_azienda_passata+'/'+this.id_azienda_padre_passata+'?qta_add='+this.name)
        .then(response => { 
          console.log("risposta server --------")
          console.log(response.data)
          console.log("response.data.messaggio -> "+response.data.messaggio)
          
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['my-modal-pezzi'].toggle('#toggle-btn')
          })

          if(response.data.status===200){
            //risposta positiva
            this.$swal({
                icon: 'success',
                text: response.data.messaggio,
                confirmButtonText: 'chiudi',
                customClass: {
                confirmButton: 'btn btn-success',
                },
            })

            //aggiorna numero di articoli nella lista carrello nel header
            //this.loadItems();
          } else if(response.data.status===201){   
            this.$swal({
                  icon: 'warning',
                  title: 'Attenzione',
                  text: response.data.messaggio,
                  confirmButtonText: 'chiudi',
                  customClass: {
                  confirmButton: 'btn btn-warning',
                  },
              })

          } else {
          //risposta negativa (errore sul server)
              this.$swal({
                  icon: 'error',
                  title: 'Si è verificato un errore',
                  text: response.data.messaggio,
                  customClass: {
                  confirmButton: 'btn btn-outline-primary',
                  },
              })
          }

          // Update count in cart items state
          this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', response.data.articoli_carrello)
        }).catch(e => {
            console.log(e);
        })
    },
  }
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
